<template>
  <div class="wrapper ">
    <div class="wrapper__container">
    <jublia-header  /> 
    <div class="container container-transparent  ">
      
      <jublia-h1 class="text-jublia-bluest  mb-4" >
        {{ $t('home.title') }}
      </jublia-h1>
    
      <jublia-p class="flex-grow text " style="font-weight: 500 !important;">
        {{ $t('home.text') }}
      </jublia-p>
      <div class="mt-8 w-full">
        <video
          :src="require(`../assets/mp4/${getLinkVideo()}`)"
          class="container-video"
          controls
          
        ></video>
        <!-- poster="../assets/img/Jublia_logo-en.png"-->
        
       <!-- <div class="mt-8 flex md:mx-auto md:w-1/2">-->
         <router-link to="/menu"  class="mt-6 flex md:mx-auto md:w-1/2">
            <jublia-button>
              <div class="text-xl font-bold 
              flex justify-between items-center mr-3">
                 {{ $t('user-settings.back') }}
              </div>
              
            </jublia-button>
            </router-link >
          
       </div>
       
      </div>
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import JubliaP from '@/components/text/jublia-p'
  import { useI18n } from "vue3-i18n";
import JubliaHeader from '@/components/header/jublia-header'
 
import JubliaButton from '@/components/button/jublia-button'
import JubliaH1 from '@/components/text/jublia-h1'
//console.log(useI18n.locale)
export default {
  name: 'video-personal',
  
  components: {
 //JubliaButton,
  'jublia-p':JubliaP,
    'jublia-h1':JubliaH1,
    'jublia-button':JubliaButton,
     'jublia-header':JubliaHeader,

  },
  data: () => ({
    i18n: useI18n(),
   
  }),
   beforeMount() {
    const query = this.$route.query;
  //query.page = page;
  //console.log(this.$i18n.getLocale())
  if(query.lang){
  this.$i18n.setLocale(query.lang)
//console.log( query.lang)
}
  },
  methods: {
    //...mapMutations('menu',['toggleMenu']),
    getLinkVideo() {
    // let v='jublia_FR.mp4'
      //console.log(this.$i18n.getLocale())
      if (this.$i18n.getLocale() === 'fr') return 'jublia_FR.mp4'
      if (this.$i18n.getLocale() === 'en') return 'jublia_EN.mp4'
     
      return 'jublia_FR.mp4'
    }
  }

}
</script>
<style scoped>

.container-video {
  @apply rounded-lg;
  width: 100%;
  max-width: 540px;
  margin: 0 auto;
  border: 4px solid #006699;
  @media all and (min-width: 767px) {
    width: 50%;
   
  }
}
.play-button {
  width: 152px;
  height: 152px;
  position: relative;
  z-index:10;
  color:red;
  cursor: pointer;
}
.container-transparent{
  @media all and (min-width: 768px) {
  /* @apply pl-16;*/
   /* margin: 0 auto;*/
  }
}

[controls] {
    background: rgb(248, 246, 246);
    color: rgb(238, 229, 229);
    z-index:10;
}

::controls, ::-controls, ::-webkit-controls {
    background: rgb(248, 242, 243);
    color: rgb(241, 240, 240);
    z-index:10;
}

</style>