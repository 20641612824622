<template>
  <div class="wrapper ">
      <div class="wrapper__container">
        <jublia-header />
    <div class="container container-transparent">
     
      <div class="container-ins">
         <jublia-h1 class="DINBold text-jublia-bluest mb-6">
          {{ $t("menu-principal.title") }}
        </jublia-h1>
<!--grid grid-cols-2 -->
        <div class="mt-8 w-full">
          <div style="" class="menu-grid relative gap-4 mx-6 ">
             <div @click="goi()" class="  flex flex-col items-center justify-center">
             <div
              class=" item h-full w-1/2 px-8 
                bg-jublia-blue rounded-full flex flex-col items-center justify-center"
            >
                <img
                style="padding-bottom: 0.6rem;"
                src="@/assets/icons/photo-ico.png"
                alt="next button"
                width="80"
                height="18"
              />
               <p class="text mt-8">{{ $t("menu-principal.photo") }}</p>
            </div>
             </div>
              <div @click="go(`/result`)" class="  flex flex-col items-center justify-center">
             <div
              class=" item h-full w-1/2 px-8 
                bg-jublia-blue rounded-full flex flex-col items-center justify-center"
            >
                <img
                src="@/assets/icons/calculate-ico.png"
                alt="next button"
                width="60"
                height="18"
              />
               <p class="text mt-8">{{ $t("menu-principal.calculator") }}</p>
            </div>
             </div>
             <div @click="go(`/settings-remaind`)" class="  flex flex-col items-center justify-center">
             <div
              class=" item h-full w-1/2 px-8 
                bg-jublia-blue rounded-full flex flex-col items-center justify-center"
            >
                <img
                src="@/assets/icons/remaind-ico.png"
                alt="next button"
                width="60"
                height="18"
              />
               <p class="text mt-8">{{ $t("menu-principal.remaind") }}</p>
            </div>
             </div>
             <div  @click="go(`/video-personal`)" class="  flex flex-col items-center justify-center">
             <div
              class=" item h-full w-1/2 px-8 
                bg-jublia-blue rounded-full flex flex-col items-center justify-center"
            >
                <img
                src="@/assets/icons/video-ico.png"
                alt="next button"
                width="60"
                height="18"
              />
               <p class="text mt-8">{{ $t("menu-principal.video") }}</p>
            </div>
             </div>

            <div @click="go(`/user-settings`)" class="  flex flex-col items-center justify-center">
             <div
              class=" item h-full w-1/2 px-8 
                bg-jublia-blue rounded-full flex flex-col items-center justify-center"
            >
                <img
                src="@/assets/icons/settings-ico.png"
                alt="next button"
                width="100"
                height="18"
              />
               <p class="text mt-8">{{ $t("menu-principal.setting") }}</p>
            </div>
             </div>

             <div v-if="v=='d'"  @click="go(`/survey-personal`)" class="  flex flex-col items-center justify-center">
             <div
              class=" item h-full w-1/2 px-8 
                bg-jublia-blue rounded-full flex flex-col items-center justify-center"
            >
                <img
                src="@/assets/icons/survay-ico.png"
                alt="next button"
                width="65"
                height="18"
              />
               <p class="text mt-8">{{ $t("menu-principal.survey") }}</p>
            </div>
             </div>
          </div>
          <!--<router-link to="/login">
            <jublia-button

 v-if="v=='d'"
             class="relative mt-4 border-jublia-blue"
             type="submit"
           >
            Facebook
           </jublia-button>
         </router-link>
          <jublia-button
          v-on:click="loginGoogle()"
              color="red"
             class="relative mt-4 border-jublia-blue"
             type="submit"
           >
            Google
           </jublia-button>
          -->
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
import JubliaP from "@/components/text/jublia-p";
import axios from "axios";
import JubliaButton from "@/components/button/jublia-button";
import JubliaH1 from "@/components/text/jublia-h1";
import JubliaH3 from "@/components/text/jublia-h3";
import { useI18n } from "vue3-i18n";
import { reactive, ref, computed } from "vue";
import { useRouter } from "vue-router";
import {mapState, mapMutations ,mapActions,mapGetters,useStore } from "vuex";
import JubliaHeader from "@/components/header/jublia-header";

export default {
  name: "login",

  components: {
    //JubliaButton,
    "jublia-button": JubliaButton,
    "jublia-header": JubliaHeader,
    "jublia-h3": JubliaH3,
    "jublia-h1": JubliaH1,
    "jublia-p": JubliaP,
  },

  setup() {
    const first = ref("");
    const last = ref("");
    const email = ref("");
    const errorRegistration = ref(null);
    const password = ref("");
    /**libre */
    const i18n = useI18n();
    const store = useStore();
    const router = useRouter();
   
    const loginGoogle = () => {
      store.dispatch("login/providerGoogle").then(() => {
        router.push({ name: "din" });
      });
    };

    return {
     
    };
  },
  data() {
    return {
     v: sessionStorage.getItem('vb')||'d',
     k: []
    
  }},
   computed: {
    ...mapState({
      schemaSelection: (state) => state.treatment.schemaSelection,
      url: (state) => state.login.uri,
      profil: (state) => state.login.userProfile,
   
    }),
  ...mapGetters({
     doneCount: 'login/userProfile',
     })
  
  },
  mounted() {
    const prev= sessionStorage.getItem('log')
  const v=this.$i18n.getLocale()
     if(prev=='first'){
       sessionStorage.setItem('log','none')
     window.location.reload(false);
     localStorage.setItem('lang',v)
     
     }
  },
  methods: {
    go(a){
   this.$router.push(a)
    },
     goi(){
    this.$router.push(`/${this.url}/upload-personal`)
    },
  }
};
</script>
<style scoped>
.container-ins {
  /* width: 100%;*/
  max-width: 870px;
  margin: 0 auto;
  
}
.menu-grid{
  
   display: grid;
  grid-template-columns: repeat(2,  1fr);
  grid-template-rows: 160px 160px 168px;
  grid-auto-flow: row; 
gap: 18px;
@media all and (min-width: 767px) {
  margin-top: 4rem !important;
  width: 61%;
margin: auto;
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) 
 {@apply mx-0; grid-template-rows: 150px 150px 162px;}

}
.item:hover{
transform: scale(1.05);
 transition:  .5s ease;
}
.item{
 
  @apply bg-jublia-blue cursor-pointer;
  flex: 1 1 auto;
     width: 166px;
    height: 95%;
    font-size: 12px;
    color: #fff !important;
   /* background-color: #db6525;
    border: 4px solid #00B2AC;*/
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
     @media only screen and (min-device-width : 320px) and (max-device-width : 600px) {width: 154px;}
    
}
.text {
    /* font-family: DIN Next LT Pro; */
    /* text-align: justify !important; */
    font-size: 1rem;
    margin-top: .3rem;
    text-align: center !important;
    line-height: 140%;
     color: #fff !important;
     width: 7rem
}
.container-content {
  @apply w-full;
  @apply m-auto;
  @apply pb-8;
  @apply px-8;
  max-width: 900px;

  @media all and (min-width: 767px) {
    max-width: 500px;
  }
}
.container {

  @media all and (min-width: 768px) {
   
  }
}
</style>
